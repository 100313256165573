<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" v-if="!queryParam.id">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="企业">
              <a-auto-complete
                v-model="queryParam.corporation"
                :data-source="dataSource"
                placeholder="请输入"
                @select="onSelect"
                @search="onSearch"
                @change="onChange"
              />
              <span>{{ tip }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select default-value="" style="width: 100%" v-model="queryParam.status">
                <a-select-option key="" value="">
                  全部
                </a-select-option>
                <a-select-option v-for="(value, key) in this.$Dictionaries.ContractStatus" :key="key" :value="key">
                  {{ value }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-page-header
        v-if="queryParam.id"
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="合同信息"
        @back="() => $router.go(-1)">
      </a-page-header>
    </div>

    <div class="table-operator" v-if="!queryParam.id">
      <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="color" slot-scope="color">
        <a-tag
          style="height: 15px"
          :color="color"
        >
          {{ '    ' }}
        </a-tag>
      </span>
      <span slot="annex_url" slot-scope="annex_url, record">
        <a-avatar
          v-if="(getFileSuffix(annex_url) !== 'pdf') && annex_url"
          slot="annex_url"
          :src="annex_url"
          shape="square"
          @click="openPreview(annex_url)"
        />
        <a :href="annex_url" :title="getFileFullName(record.annex_url)" v-if="getFileSuffix(annex_url) === 'pdf'" target="_blank">已上传</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="privew(record)">查看</a>
        </template>
        <a-divider type="vertical" v-if="!(record.status===2&&record.annex_url!==undefined&&record.annex_url.length>0)" />
        <template v-if="!(record.status===2&&record.annex_url!==undefined&&record.annex_url.length>0)">
          <a @click="handlefujian(record)"> 附件</a>
        </template>
        <a-divider type="vertical" />
        <template>
          <a @click="propertyDetail(record)">详情</a>
        </template>
        <a-divider type="vertical" v-if="record.status !== 3"/>
        <a-dropdown v-if="record.status !== 3">
          <a class="ant-dropdown-link">
            更多 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="record.status === 1" v-action:edit>
              <a @click="rent_contract_partial_update(record)" >签约完成</a>
            </a-menu-item>
            <a-menu-item v-if="record.status !== 4" v-action:delete>
              <a @click="handleDelet(record)">废除</a>
            </a-menu-item>
            <a-menu-item v-if="record.status === 2">
              <a @click="handleFinish(record)" >完成</a>
            </a-menu-item>
            <a-menu-item v-if="record.status !== 3">
              <a @click="timeCreateBill(record)" >时间账单</a>
            </a-menu-item>
            <a-menu-item v-if="record.status !== 3">
              <a @click="createBill(record)">自动账单</a>
            </a-menu-item>
            <a-menu-item v-if="record.status !== 3">
              <a @click="propertyBill(record)">物业账单</a>
            </a-menu-item>
            <a-menu-item v-if="record.status !== 3">
              <a @click="rentBill(record)">租金账单</a>
            </a-menu-item>
            <a-menu-item v-if="record.status !== 3">
              <a @click="guaranteeBill(record)">保证金账单</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </s-table>
    <a-modal
      title="生成账单"
      v-if="bookvisble"
      :width="1080"
      :visible="bookvisble"
      :confirm-loading="bookLoading"
      :footer="null"
      @cancel="handlePropertyCancel"
    >
      <a-button type="primary" @click="rent_bill_c">生成租金账单</a-button>
      <a-divider type="vertical" />
      <a-button type="dashed" @click="property_bill_c">生成物业账单</a-button>
    </a-modal>
    <a-modal
      title="按时间生成账单"
      v-if="billvisble"
      :width="1080"
      :visible="billvisble"
      :confirm-loading="billLoading"
      :footer="null"
      @cancel="handleBillCancel"
    >
      <a-form style="width: 100%">
        <a-row :gutter="48">
          <a-col :md="24" :sm="24">
            <a-form-item label="时间" style="width: 100%">
              <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 60%">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-button type="primary" @click="handleRentOk">生成租金账单</a-button>
      <a-divider type="vertical" />
      <a-button type="dashed" @click="handlePropertyOk">生成物业账单</a-button>
    </a-modal>
    <create-form
      v-if="visible"
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <property-bill-form
      v-if="property_bill_visible"
      ref="propertyBillModal"
      :visible="property_bill_visible"
      :loading="confirmPropertyBillLoading"
      :model="mdl"
      @cancel="handlePropertyBillCancel"
      @ok="handlePropertyBillOk"
    />
    <rent-bill-form
      v-if="rent_bill_visible"
      ref="rentBillModal"
      :visible="rent_bill_visible"
      :loading="confirmRentBillLoading"
      :model="mdl"
      @cancel="handleRentBillCancel"
      @ok="handleRentBillOk"
    />
    <guarantee-bill-form
      v-if="guarantee_bill_visible"
      ref="guaranteeBillModal"
      :visible="guarantee_bill_visible"
      :loading="confirmGuaranteeBillLoading"
      :model="mdl"
      @cancel="handleGuaranteeBillCancel"
      @ok="handleGuaranteeBillOk"
    />
    <preview
      ref="preview"
      v-if="previsible"
      :visible="previsible"
      :loading="preLoading"
      :content="content"
      @cancel="handlepreCancel"
      @ok="handlepreOk"
    />
    <create-fu-jian-form
      ref="createAttachmentModal"
      v-if="visibleAttachment"
      :visible="visibleAttachment"
      :loading="confirmAttachmentLoading"
      :model="mdl"
      @cancel="handleAttachmentAddCancel"
      @ok="handleAttachmentOk"
    />
    <finish-form
      ref="finishFormModal"
      v-if="visibleFinishForm"
      :visible="visibleFinishForm"
      :loading="confirmFinishFormLoading"
      @cancel="handleFinishFormCancel"
      @ok="handleFinishFormOk"
    />
    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import {
  rent_contract_list,
  rent_contract_create,
  rent_contract_delete,
  rent_contract_partial_update,
  rent_contract_attachment_create, rent_contract_properties
} from '@/api/rent_contract'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import Preview from '../modules/preview'
import { contract_template_read } from '@/api/contract'
import { guarantee_bill_manual_create, manual_rent_bill_create, rent_bill_create } from '@/api/rent_bill'
import { manual_property_bill_create, property_bill_create } from '@/api/property_bill'
import { corporation_list } from '@/api/corporation'
import { getForms, createProcessResult } from '@/api/design'
import CreateFuJianForm from '@/views/a-rent_contract/modules/CreateFuJianForm'
import PropertyBillForm from '@/views/a-rent_contract/modules/PropertyBillForm'
import RentBillForm from '@/views/a-rent_contract/modules/RentBillForm'
import GuaranteeBillForm from '@/views/a-rent_contract/modules/GuaranteeBillForm'
import FinishForm from '@/views/a-rent_contract/modules/FinishForm'

export default {
  name: 'TableList',
  components: {
    RentBillForm,
    GuaranteeBillForm,
    PropertyBillForm,
    CreateFuJianForm,
    STable,
    CreateForm,
    Preview,
    FinishForm
  },
  data () {
    return {
      pageNum: 1,
      property_bill_visible: false,
      rent_bill_visible: false,
      guarantee_bill_visible: false,
      previewvisible: false,
      previewurl: '',
      dataSource: [],
      corporation_id: null,
      tip: '',
      corList: [],
      bookvisble: false,
      bookLoading: false,
      current_contract_id: '',
      billvisble: false,
      billLoading: false,
      billTime: {},
      content: '',
      previsible: false,
      loading: false,
      confirmLoading: false,
      confirmPropertyBillLoading: false,
      confirmRentBillLoading: false,
      confirmGuaranteeBillLoading: false,
      confirmAttachmentLoading: false,
      preLoading: false,
      dictionaries: Dictionaries,
      visibleAttachment: false,
      visibleFinishForm: false,
      confirmFinishFormLoading: false,
      attachmentListItemId: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '企业',
          dataIndex: 'corporation',
          ellipsis: true
        },
        {
          title: '开始日期',
          dataIndex: 'start_date',
          ellipsis: true
        },
        {
          title: '结束日期',
          dataIndex: 'end_date',
          ellipsis: true
        },
        {
          title: '合同编号',
          dataIndex: 'number',
          ellipsis: true
        },
        {
          title: '企业注册地址',
          dataIndex: 'address',
          ellipsis: true
        },
        {
          title: '负责人',
          dataIndex: 'in_charge_person',
          ellipsis: true
        },
        {
          title: '联系人',
          dataIndex: 'contact_person',
          ellipsis: true
        },
        {
          title: '联系电话',
          dataIndex: 'contact_phone',
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.ContractStatus[text],
          ellipsis: true
        },
        {
          title: '附件',
          dataIndex: 'annex_url',
          scopedSlots: { customRender: 'annex_url' }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true
        },
        {
          width: 280,
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        console.log(this.$Dictionaries.ContractStatus, ' 我是合同状态')
        return rent_contract_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      }
    }
  },
  created () {
    this.tableOption()
    this.setCurrentPage()
    console.log(this.$Dictionaries, ' 我是合同状态')
    const contractId = this.$route.params.id
    if (contractId) {
      this.queryParam.id = contractId
    }
  },
  methods: {
    setCurrentPage () {
      // let page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        // sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      } else {
        this.pageNum = 1
      }
    },
    getFileSuffix (filename) {
      // console.log(filename, '787800')
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    getFileName (filename) {
      let name = filename.substring(filename.lastIndexOf('/') + 1)
      const suffix = filename.substring(filename.lastIndexOf('.')).toLowerCase()
      console.log(name, name.length)
      name = name.length - suffix.length > 10 ? name.substring(0, 5) + '...' + name.substring(8, 10) + suffix : name
      return name
    },
    getFileFullName (filename) {
      return filename.substring(filename.lastIndexOf('/') + 1)
    },
    openPreview (img_url) {
      const fileType = ['pdf', 'jpg', 'jpeg', 'png']
      const suffix = img_url.substring(img_url.lastIndexOf('.') + 1).toLowerCase()
      if (fileType.indexOf(suffix) > -1) {
        this.previewvisible = true
        this.previewurl = img_url
      } else {
        this.$message.error('附件错误，不支持预览')
      }
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
      })
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      this.queryParam.corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id

      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    propertyDetail (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: '/rent_contract/' + record.id + '/zone_property_cell/', query: { name: record.name } })
    },
    rent_contract_partial_update (record) {
      // rent_contract_partial_update({ status: 2 }, record.id).then((res) => {
      //   this.$refs.table.refresh()
      // })
      this.createContractApproval([record.id], 'contract_bill')
    },
    privew (record) {
      rent_contract_properties(record.id).then(({ data }) => {
        console.log(data, 123123, record)
        let arrstr = ''
        const values = Object.assign({}, record)
        values.start_date = moment(values.start_date).format('YYYY年MM月DD日')
        values.end_date = moment(values.end_date).format('YYYY年MM月DD日')
        values.deposit = (values.deposit / 100).toFixed(2)
        data.forEach((data, index) => {
          arrstr += '</br>' + data.zone_property_cell__zone_property__name + '-' + data.zone_property_cell__name + '，租赁面积：' + data.used.toFixed(2) + '(平方米)' + '，物业费用：' + (data.property_price / 10000).toFixed(4) + '(元)/平米/天' + '，租金费用：' + (data.rental_price / 100).toFixed(6) + '(元)/平米/天'
        })
        values.rent_address = arrstr
        console.log('form, 数据', values)
        contract_template_read(values.contract_template_id).then(({ data }) => {
          console.log(data, '我是模板')
          const beforedata = data.content.replace(/\{(\w+)\}/gi, function (a, b) {
            console.log(values[b], '替换')
            return values[b]
          })
          this.previsible = true
          this.content = beforedata
          console.log(this.content, '我到底是个啥')
        })
      })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      // this.mdl = null
      // this.visible = true
      this.$router.push('/rent_contract/create/')
    },
    handlepreCancel () {
      this.previsible = false
    },
    handlepreOk () {
      this.previsible = false
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePropertyBillCancel () {
      this.property_bill_visible = false
      const form = this.$refs.propertyBillModal.form
      form.resetFields()
    },
    handleRentBillCancel () {
      this.rent_bill_visible = false
      const form = this.$refs.rentBillModal.form
      form.resetFields()
    },
    handleGuaranteeBillCancel () {
      this.guarantee_bill_visible = false
      const form = this.$refs.guaranteeBillModal.form
      form.resetFields()
    },
    handleAttachmentAddCancel () {
      this.visibleAttachment = false
      const form = this.$refs.createAttachmentModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleFinishFormCancel () {
      this.visibleFinishForm = false
      const form = this.$refs.finishFormModal.form
      form.resetFields()
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelet (record) {
     this.visibleFinishForm = true
      this.mdl = record
      this.mdl.my_type = 'delete'
    },
    handleFinish (record) {
      this.visibleFinishForm = true
      this.mdl = record
      this.mdl.my_type = 'finish'
    },
    createBill (record) {
      this.current_contract_id = record.id
      this.bookvisble = true
      this.bookLoading = false
    },
    propertyBill (record) {
      this.property_bill_visible = true
      this.confirmPropertyBillLoading = false
      this.current_contract_id = record.id
    },
    rentBill (record) {
      this.rent_bill_visible = true
      this.confirmRentBillLoading = false
      this.current_contract_id = record.id
    },
    guaranteeBill (record) {
      this.guarantee_bill_visible = true
      this.confirmGuaranteeBillLoading = false
      this.current_contract_id = record.id
    },
    handlefujian (record) {
      this.visibleAttachment = !this.visibleAttachment
      this.attachmentListItemId = record.id
      // this.$router.push('/rent_contract/attachment/' + record.id)
    },
    // 租金账单创建
    rent_bill_c () {
      rent_bill_create({ create_mode: 1, contract_id: this.current_contract_id }).then((res) => {
        console.log(res, '我是创建账单')
        if (res.success) {
          this.$message.success('成功')
          this.createContractApproval(res.data, 'rent_bill')
        }
      })
    },
    // 物业账单创建
    property_bill_c () {
      property_bill_create({ create_mode: 1, contract_id: this.current_contract_id }).then((res) => {
        console.log(res, '我是创建账单')
        if (res.success) {
          this.$message.success('成功')
          this.createContractApproval(res.data, 'property_bill')
        }
      })
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.billTime.start_date = dateStrings[0]
      this.billTime.end_date = dateStrings[1]
    },
    timeCreateBill (record) {
      this.current_contract_id = record.id
      this.billvisble = true
      this.billLoading = false
    },
    // 按照时间创建租金账单
    handleRentOk () {
      const param = Object.assign({}, this.billTime, { create_mode: 2, contract_id: this.current_contract_id })
      rent_bill_create(param).then((res) => {
        console.log(res)
        if (res.success) {
          this.$message.success('成功')
          this.createContractApproval(res.data, 'rent_bill')
        }
        this.billvisble = false
        this.billLoading = false
      }).finally(() => {
        this.billLoading = false
      })
    },
    handleBillCancel () {
      this.billvisble = false
      this.billLoading = false
    },
    handlePropertyCancel () {
      this.bookvisble = false
      this.bookLoading = false
    },
    // 按照时间创建物业账单
    handlePropertyOk () {
      const param = Object.assign({}, this.billTime, { create_mode: 2, contract_id: this.current_contract_id })
      property_bill_create(param).then((res) => {
        console.log(res)
        this.billvisble = false
        this.billLoading = false
        if (res.code === 1000) {
          this.createContractApproval(res.data, 'property_bill')
        }
      }).finally(() => {
        this.billLoading = false
      })
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handlePropertyBillOk () {
      // 物业账单手动创建
      const form = this.$refs.propertyBillModal.form
      this.confirmPropertyBillLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.contract_id = this.current_contract_id
          values.create_mode = 3 // 手动创建，mode默认为3
          values.payment_date = moment(values.payment_date).format('YYYY-MM-DD')
          values.start_date = moment(values.start_date).format('YYYY-MM-DD')
          values.end_date = moment(values.end_date).format('YYYY-MM-DD')
          values.total_payment = Math.round(values.total_payment * 100)
          // 物业计算使用厘
          values.official_property = Math.round(values.official_property * 10000)
          values.plant_property = Math.round(values.plant_property * 10000)
          // 手动新增
          manual_property_bill_create(values).then(res => {
            this.property_bill_visible = false
            this.confirmPropertyBillLoading = false
            // 重置表单数据
            form.resetFields()
            if (res.code === 1000) {
              // 刷新表格
              this.$refs.table.refresh()
              this.createContractApproval(res.data, 'property_bill')
              console.log('成功------')
            }
          }).finally(() => {
            this.confirmPropertyBillLoading = false
          })
        } else {
          this.confirmPropertyBillLoading = false
        }
      })
    },
    handleRentBillOk () {
      // 租金账单手动创建
      const form = this.$refs.rentBillModal.form
      this.confirmRentBillLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.contract_id = this.current_contract_id
          values.create_mode = 3 // 手动创建，mode默认为3
          values.payment_date = moment(values.payment_date).format('YYYY-MM-DD')
          values.start_date = moment(values.start_date).format('YYYY-MM-DD')
          values.end_date = moment(values.end_date).format('YYYY-MM-DD')
          values.total_payment = Math.round(values.total_payment * 100)
          // // 租金计算使用分
          values.official_rent = Math.round(values.official_rent * 100)
          values.plant_rent = Math.round(values.plant_rent * 100)
          // 新增
          manual_rent_bill_create(values).then(res => {
            this.rent_bill_visible = false
            this.confirmRentBillLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()

            console.log('成功------')
            this.createContractApproval(res.data, 'rent_bill')
          }).finally(() => {
            this.confirmRentBillLoading = false
          })
        } else {
          this.confirmRentBillLoading = false
        }
      })
    },
    handleGuaranteeBillOk () {
      // 保证金账单创建
      const form = this.$refs.guaranteeBillModal.form
      this.confirmGuaranteeBillLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.contract_id = this.current_contract_id
          // values.create_mode = 5 // 创建保证金账单
          values.create_date = moment(values.create_date).format('YYYY-MM-DD')
          // values.start_date = moment(values.start_date).format('YYYY-MM-DD')
          // values.end_date = moment(values.end_date).format('YYYY-MM-DD')
          values.total_payment = Math.round(values.total_payment * 100)
          // 新增
          guarantee_bill_manual_create(values).then(res => {
            this.guarantee_bill_visible = false
            this.confirmGuaranteeBillLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()

            console.log('成功------')
            this.createContractApproval(res.data, 'guarantee_bill')
          }).finally(() => {
            this.confirmGuaranteeBillLoading = false
          })
        } else {
          this.confirmRentBillLoading = false
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.start_date = moment(values.start_date).format('YYYY-MM-DD')
          values.end_date = moment(values.end_date).format('YYYY-MM-DD')
          // 物业计算使用厘
          values.official_property = Math.round(values.official_property * 10000)
          values.plant_property = Math.round(values.plant_property * 10000)
          // 租金计算使用分
          values.official_rent = Math.round(values.official_rent * 100)
          values.plant_rent = Math.round(values.plant_rent * 100)
          values.deposit = Math.round(values.deposit * 100)
          // 新增
          rent_contract_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()

            console.log('删除成功------')
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleAttachmentOk (e) {
      const form = this.$refs.createAttachmentModal.form
      this.confirmAttachmentLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          if (!values.url) {
            this.$message.error('上传失败，请重新上传文件')
            this.confirmAttachmentLoading = false
            return
          }
          // values.contract_id = this.$route.params.id
          values.contract_id = this.attachmentListItemId
          values.annex_url = values.url && (e.actionUrl + '/' + values.url) || ''
          console.log(values)
          rent_contract_attachment_create(values).then(res => {
            this.visibleAttachment = false
            this.confirmAttachmentLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
            .catch((err) => {
              console.log(err)
              this.confirmAttachmentLoading = false
            })
        } else {
          this.confirmAttachmentLoading = false
        }
      })
    },
    handleFinishFormOk (data) {
      const form = this.$refs.finishFormModal.form
      this.confirmFinishFormLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          if (this.mdl.my_type === 'finish') {
            rent_contract_partial_update({ ...data, status: 4 }, this.mdl.id).then(res => {
              this.visibleFinishForm = false
              this.confirmFinishFormLoading = false
              this.mdl = {}
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
              .catch((err) => {
                console.log(err)
                this.confirmFinishFormLoading = false
              })
          } else {
            rent_contract_delete(data, this.mdl.id).then((res) => {
              console.log(res)
              console.log('删除成功------')
              this.visibleFinishForm = false
              this.confirmFinishFormLoading = false
              this.mdl = {}
              // 重置表单数据
              form.resetFields()
              this.$refs.table.refresh()
            }).catch((err) => {
              console.log(err)
              this.confirmFinishFormLoading = false
            })
          }
        } else {
          this.confirmFinishFormLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    createContractApproval (ids, type) {
      getForms({ is_stop: 0, template_type: type }).then(res => {
        console.log(res)
        this.shenpi_list = res.data.entries
        if (this.shenpi_list && this.shenpi_list.length > 0) {
          for (let i = 0, len = this.shenpi_list.length; i < len; i++) {
            const item = this.shenpi_list[i]
            const settings = JSON.parse(item.settings)
            if (settings) {
              const notify = settings.notify
              if (notify) {
                const types = notify.types
                if (types) {
                  if (types.type === type) {
                    this.createProcessNotice(item.id, ids, type)
                    break
                  }
                }
              }
            }
          }
        } else {
          // this.$message.error('先创建合同审批模板')
          this.createProcessNotice(-1, ids, type)
        }
      })
    },
    createProcessNotice (template_id, ids, type) {
      const data = {
        'data_ids': ids,
        'template_id': template_id,
        'template_type': type
      }
      createProcessResult(data).then(res => {
        console.log(res)
        if (res.code === 1000) {
          console.log('创建成功')
        }
      })
    }
  }
}
</script>
